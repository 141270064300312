@font-face {
    font-family: "Poppins";
    src: url("Poppins/Poppins-Regular.ttf");
    font-style: normal;
    font-weight: normal;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Poppins Bold";
    src: url("Poppins/Poppins-Bold.ttf");
    font-style: normal;
    font-weight: 800;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Lato";
    src: url("Lato/Lato-Regular.ttf");
    font-style: normal;
    font-weight: normal;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Lato Bold";
    src: url("Lato/Lato-Bold.ttf");
    font-style: normal;
    font-weight: 800;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Office Code Pro";
    src: url("OfficeCodePro/TTF/OfficeCodePro-Regular.ttf");
    font-style: normal;
    font-weight: normal;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Office Code Pro Bold";
    src: url("OfficeCodePro/TTF/OfficeCodePro-Medium.ttf");
    font-style: normal;
    font-weight: 800;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Open Sans";
    src: url("OpenSans/static/OpenSans-Regular.ttf");
    font-style: normal;
    font-weight: normal;
    /*font-display: swap;*/
}


@font-face {
    font-family: "Open Sans Semi Bold";
    src: url("OpenSans/static/OpenSans-SemiBold.ttf");
    font-style: normal;
    font-weight: 600;
    /*font-display: swap;*/
}

@font-face {
    font-family: "Open Sans Bold";
    src: url("OpenSans/static/OpenSans-Bold.ttf");
    font-style: normal;
    font-weight: 800;
    /*font-display: swap;*/
}
