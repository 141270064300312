a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}


html,
body,
body > div:first-child,
div#__next {
  height: 100%;
}
#htmlcmscontent img {
  max-width: 100%;
  border: solid 1px #F5F5F5;
  margin-top: 16px;
  margin-bottom: 16px;
}
